require('./bootstrap');

$('.onClickSetDisabled').each((key, item) => {
    $(item).on('click', function () {
        setTimeout(() => {
            $(this).attr('disabled', true)
        }, 0);
    })
});

$('footer p.footer-copyright > span:first-child').text('© ' + (new Date()).getFullYear())

document.cookie = `timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}; path=/`;

window.getCookie = (key, defaultVal = null) => {
    let name = key + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return defaultVal;
}

window.setCookie = (key, value, time) => {
    const d = new Date();
    d.setTime(d.getTime() + time);
    let expires = "expires=" + d.toUTCString();
    document.cookie = key + "=" + value + ";" + expires + ";path=/";
}

if (!window.getCookie('hideCookieBox')) {
    $('.cookieBox').slideDown(300);
}

$('.closeCookieBox').on('click', function () {
    $('.cookieBox').slideUp(200);
    window.setCookie('hideCookieBox', 1, 60 * 24 * 60 * 60 * 1000) // 2 Months
});

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$('.loginWithAction').on('click', function () {
    if ($(this).data('callback')) {
        $('#loginModalForm').attr('data-callback', $(this).data('callback'));
        $('#registerModalForm').attr('data-callback', $(this).data('callback'));
    } else if ($(this).data('callback-element')) {
        $('#loginModalForm').attr('data-callback-element', $(this).data('callback-element'));
        $('#registerModalForm').attr('data-callback-element', $(this).data('callback-element'));
    }
})

$('#loginModalForm, #loginAdminModalForm').on('submit', function (e) {
    e.preventDefault();
    const form = $(this);
    const formData = form.serializeArray();
    const loginBtn = form.find('button[type=submit]');
    loginBtn.attr('disabled', true);
    clearFormErrors(form);

    const data = {
        email: formData[1].value,
        password: formData[2].value
    }

    $.ajax({
        url: form.attr('action'),
        method: form.attr('method'),
        headers: {
            'Content-type': 'application/json',
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: JSON.stringify(data),
        success: () => onLoginRegisterSuccess($(this)),
        error: err => onLoginRegisterError(form, err, loginBtn)
    })
});

$('#registerModalForm').on('submit', function (e) {
    e.preventDefault();
    const form = $(this);
    const registerBtn = form.find('button[type=submit]');
    registerBtn.attr('disabled', true);
    $('.loader').addClass('show');
    clearFormErrors(form);
    const formData = new FormData(this);
    grecaptcha.ready(function() {
        getRecaptchaToken().then(({token, key}) => {
            const data = {
                name: formData.get('name'),
                email: formData.get('email'),
                password: formData.get('password'),
                agree: formData.get('agree'),
                [key]: token,
            }

            $.ajax({
                url: form.attr('action'),
                method: form.attr('method'),
                headers: {
                    'Content-type': 'application/json',
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: JSON.stringify(data),
                success: () => onLoginRegisterSuccess($(this)),
                error: err => onLoginRegisterError(form, err, registerBtn)
            });
        });
    });
});

$("#loginModal").on("hidden.bs.modal", function () {
    clearFormErrors($(this));
});

$("#registerModal").on("hidden.bs.modal", function () {
    clearFormErrors($(this));
});

$(".toggle-password").click(function () {
    $(this).toggleClass("eye-slash");
    let input = $($(this).attr("toggle"));

    if (input.attr("type") === "password") {
        input.attr("type", "text");
    } else {
        input.attr("type", "password");
    }
});

$('#contact-form-grapesjs').on('submit', function (e) {
    e.preventDefault();
    const form = $(this);
    const btn = form.find('button[type=submit]');
    btn.attr('disabled', true);
    $('.loader').addClass('show');
    clearFormErrors(form);

    const formData = new FormData(this);

    const data = {};
    formData.forEach((value, key) => (data[key] = value));
    data['ignore'] = 1;

    $.ajax({
        url: form.attr('action'),
        method: form.attr('method'),
        headers: {
            'Content-type': 'application/json',
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: JSON.stringify(data),
        success: res => {
            window.location.reload();
        },
        error: err => onLoginRegisterError(form, err, btn),
    });
});

function clearFormErrors(parent) {
    parent.find('.invalid-feedback').remove();
    parent.find('.is-invalid').removeClass('is-invalid')
}

window.translationMessages = {};
window.addTranslationMessages = (messages) => {
    if (_.isObject(messages)) {
        window.translationMessages = messages;
        return;
    }

    messages.forEach((message) => {
        window.translationMessages[message.key] = message.value;
    })
}
window.trans = (key, group = null) => {
    if (translationMessages[key]) {
        return translationMessages[key];
    }

    return (group ? group + '.' : null) + key;
}
toastr.options = {
    "closeButton": true,
    "progressBar": true,
    "showDuration": "500",
    "hideDuration": "1000",
    "timeOut": "5000"
};

toastr.danger = function (options) {
    return toastr.error(options);
}

const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

window.addEventListener('resize', appHeight)
appHeight()

$('.tab[data-tab]').click(function (e) {
    e.preventDefault();
    $(this).tab('show');
});

$('*[data-accordion-container]').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('active');

    setTimeout(() => {
        if ($(this).hasClass('active')) {
            $(this).find('.accordion-content').css({display: 'block'})
        } else {
            $(this).find('.accordion-content').css({display: 'none'})
        }
    });
});

$('.accordion-content').on('click', function (e) {
    e.stopPropagation();
})

$('.edit-page-builder').tooltip();
